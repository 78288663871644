<template>
    <div class="vue-form">
        <div class="controls-row"
             v-if="controlsLocation === 'top'"
        >
            <div
                v-if="disabled === false && autoSave === false && layoutType !== 'tabs'"
                class="flex"
                :class="{'justify-end': controlsAlignment === 'right' }"
            >
                <div>
                    <button
                        class="button"
                        v-for="action in actions"
                        :key="action.action"
                        @click.prevent="runAction(action.action)"
                        v-html="action.label"
                        :disabled="showSaving && saving"
                    ></button>
                    <span v-if="saving && showSaving"
                    ><font-awesome-icon
                        :icon="spinnerIcon"
                        :spin="true"
                    ></font-awesome-icon
                    >{{ savingText }}</span
                    >
                </div>
                <div v-if="showCloseIcon">
                    <span class="close-icon fa fa-times" @click="close"></span>
                </div>
            </div>
            <div
                v-if="showSaving && saving && autoSave === true"
            >
                <font-awesome-icon :icon="spinnerIcon" :spin="true"></font-awesome-icon>
                {{ savingText }}
            </div>
        </div>
        <div class="text-right" v-if="showCloseIcon && controlsLocation !== 'top'">
            <span class="close-icon fa fa-times" @click="close"></span>
        </div>
        <div v-if="form.errors.hasGeneralMessage()">
            <div
                class="m-4 p-4 flex items-center"
                :class="{
          'bg-red-300': form.errors.getGeneralMessageType() === 'error',
          'bg-blue-300': form.errors.getGeneralMessageType() === 'info',
        }"
            >
                <font-awesome-icon
                    :icon="warningIcon"
                    v-if="form.errors.getGeneralMessageType() === 'error'"
                ></font-awesome-icon>
                <font-awesome-icon
                    :icon="infoIcon"
                    v-if="form.errors.getGeneralMessageType() === 'info'"
                ></font-awesome-icon>
                <div>
                    <span class="mx-4 leading-6">{{ form.errors.getGeneralMessage() }}</span>
                    <div
                        v-if="hiddenErrorMessages.length > 0"
                        class="ml-12 text-sm"
                    >
                        <ul class="list-disc">
                            <li
                                v-for="message in hiddenErrorMessages"
                                :key="message"
                            >
                                {{ message }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <form @submit.prevent="">
            <div v-if="layoutType === 'tabs'" class="form-tabs">
                <form-tabs :is-multi-step="formConfig.options?.isMultiStep ?? false">
                    <component
                        v-for="(field, index) in fields"
                        :key="field.id"
                        :name="field.field_extra.name ? field.field_extra.name : field.label"
                        :is-multi-step="formConfig.options?.isMultiStep ?? false"
                        :form-options="formConfig.options"
                        :submit-form-function="submitForm"
                        :index="index"
                        :is="getFormFieldComponent(field.widget)"
                        :field-name="field.name"
                        :disabled="disabled"
                        :auto-save="autoSave"
                        :actions="actions"
                        :saving="saving"
                        :show-saving="showSaving"
                        :saving-text="savingText"
                        @runAction="runAction"
                        v-show="field.visible && conditionValues[field.name]"
                        :children="field.children || null"
                        :class="columnWidth + ' ' + 'm-2'"
                    ></component>
                </form-tabs>
            </div>
            <div :class="{ flex: columnCount > 1 }" v-else>
                <component
                    v-for="field in fields"
                    :key="field.id"
                    :is="getFormFieldComponent(field.widget)"
                    v-show="field.visible && conditionValues[field.name]"
                    :field-name="field.name"
                    :value="getFieldValue(form.data, field)"
                    @input="(newVal) => updateValueAndConditionals(newVal, field)"
                    @options-updated="
            (newOptions) => updateOptionsForField(newOptions, field)
          "
                    :children="field.children || null"
                    :class="columnWidth + ' ' + 'm-2'"
                ></component>
            </div>
            <div class="controls-row"
                 v-if="controlsLocation === 'bottom'"
            >
                <div
                    v-if="disabled === false && autoSave === false && layoutType !== 'tabs'"
                    class="flex"
                    :class="{'justify-end': controlsAlignment === 'right' }"
                >
                    <button
                        class="button"
                        v-for="action in actions"
                        :key="action.action"
                        @click.prevent="runAction(action.action)"
                        v-html="action.label"
                        :disabled="showSaving && saving"
                    ></button>
                    <span v-if="saving && showSaving"
                    ><font-awesome-icon
                        :icon="spinnerIcon"
                        :spin="true"
                    ></font-awesome-icon
                    >{{ savingText }}</span
                    >
                </div>
                <div
                    v-if="showSaving && saving && autoSave === true"
                >
                    <font-awesome-icon :icon="spinnerIcon" :spin="true"></font-awesome-icon>
                    {{ savingText }}
                </div>
            </div>
        </form>
    </div>
</template>
<script>
import FormProps from "../mixins/FormProps";
import FormConfig from "../mixins/FormConfig";
import Actions from "../mixins/Actions";
import UpdatesValuesAndConditions from "../mixins/UpdatesValuesAndConditions";
import {Form} from "../classes/Form";
import FormColumnGroup from "./FormComponents/FormColumnGroup.vue";
import FormColumn from "./FormComponents/FormColumn.vue";
import FormText from "./FormComponents/FormText.vue";
import FormTextarea from "./FormComponents/FormTextarea.vue";
import FormSelect from "./FormComponents/FormSelect.vue";
import FormDatePicker from "./FormComponents/FormDatePicker.vue";
import FormRadio from "./FormComponents/FormRadio.vue";
import FormCheckbox from "./FormComponents/FormCheckbox.vue";
import FormAutocomplete from "./FormComponents/FormAutocomplete.vue";
import FormFiles from "./FormComponents/Files/FormFiles.vue";
import {debounce} from "lodash";
import { getChildrenForField } from "../utilities/utils";

import {
    faSpinner,
    faExclamationTriangle,
    faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";

import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import FormField from "../admin/classes/models/formField";

export default {
    name: "vue-form",

    mixins: [FormProps, FormConfig, Actions, UpdatesValuesAndConditions],

    components: {
        FormColumnGroup,
        FormColumn,
        FormText,
        FormAutocomplete,
        FormTextarea,
        FormSelect,
        FormDatePicker,
        FormRadio,
        FormCheckbox,
        FormFiles,
        FontAwesomeIcon,
    },

    data() {
        return {
            form: {},
            formDataWatcher: null,
            saving: false,
            spinnerIcon: faSpinner,
            warningIcon: faExclamationTriangle,
            infoIcon: faInfoCircle,
        };
    },

    created() {
        //var formData = cloneObject(this.formData);
        var formData = JSON.parse(JSON.stringify(this.formData));
        var data = this.defaultFields(formData);

        this.form = new Form(data, this.formConfig, this.disabled);
        this.generateConditionValues();

        if (this.autoSave) {
            this.setUpAutoSave();
        }

        this.$watch(
            "form.data",
            (updated) => {
                this.$emit("changed", updated);
            },
            {deep: true}
        );
    },

    computed: {
        controlsLocation() {
            return this.controlsArea.split("-")[0];
        },
        controlsAlignment() {
            return this.controlsArea.split("-")[1];
        },
        fields() {
            const topLevelFields =
                this.formConfig.fields.filter((field) => {
                    if (field.parent_id) {
                        return false;
                    }
                    return true;
                }).map((field) => {
                    let attributes = field;
                    if(typeof field.toJSON === 'function') {
                        attributes = field.toJSON();
                    }
                    return new FormField(attributes);
                });

            topLevelFields.forEach((topLevelField) => {
                topLevelField.children = getChildrenForField(this.formConfig, topLevelField);
            });

            return topLevelFields;
        },
        layoutType() {
            let tabField = this.fields.find((field) => {
                if (field.widget === "tab") {
                    return true;
                }
                return false;
            });

            if (tabField) {
                return "tabs";
            }

            return "normal";
        },
        columnCount() {
            var columnCount = 0;
            this.fields.forEach((field) => {
                if (field.widget === "column") {
                    columnCount++;
                }
            });

            if (columnCount === 0) {
                columnCount = 1;
            }

            return columnCount;
        },
        columnWidth() {
            if (this.columnCount === 0) {
                return null;
            }

            return "w-1/" + this.columnCount;
        },
        hiddenErrorMessages() {
            let hiddenMessages = [];

            this.form.formConfig.fields
                .filter((ff) => {
                    return !ff.visible;
                })
                .forEach((ff) => {
                    if (!this.form.errors.has(ff.value_field)) {
                        return;
                    }
                    const errorMessages = this.form.errors.get(ff.value_field);

                    if (Array.isArray(errorMessages)) {
                        errorMessages.forEach((message) => {
                            hiddenMessages.push(message);
                        });
                    } else {
                        hiddenMessages.push(errorMessages);
                    }
                });

            return hiddenMessages;
        },
    },

    provide() {
        let provide = {};

        Object.defineProperty(provide, "form", {
            enumerable: true,
            get: () => this.form,
        });

        Object.defineProperty(provide, "apiClient", {
            enumerable: true,
            get: () => this.apiClient,
        });

        Object.defineProperty(provide, "formHasJsonApi", {
            enumerable: true,
            get: () => this.useJsonApi,
        });
        return provide;
    },

    methods: {
        setUpAutoSave() {
            this.formDataWatcher = this.$watch(
                "form.data",
                debounce(function () {
                    this.submitForm();
                }, this.autoSaveTimeout),
                {deep: true}
            );
        },
    },

    watch: {
        formData: {
            handler: function (newFormData) {
                newFormData = JSON.parse(JSON.stringify(newFormData));

                if (this.formDataWatcher) {
                    this.formDataWatcher();
                }

                this.form.updateData(newFormData);
                this.generateConditionValues();

                if (this.autoSave) {
                    this.setUpAutoSave();
                }
            },
            deep: true,
        },
        formErrors: {
            handler(newFormErrors) {
                if (newFormErrors && newFormErrors.fieldErrors) {
                    this.form.errors = newFormErrors;
                }
            },
            deep: true,
        },
        forceUpdate(force) {
            if (force) {
                var newFormData = JSON.parse(JSON.stringify(this.formData));

                if (this.formDataWatcher) {
                    this.formDataWatcher();
                }

                this.form.clearFields();
                this.form.updateData(newFormData, true);

                if (this.autoSave) {
                    this.setUpAutoSave();
                }
            }

            this.$emit("update:forceUpdate", false);
        },
        disabled(disabled) {
            this.form.disabled = disabled;
        },
        // watcher to watch if outside component is directing to show saving
        isSaving(newIsSaving) {
            if (newIsSaving) {
                this.saving = true;
            } else {
                this.saving = false;
            }
        },
    },
};
</script>
